function readLess() {
  const parentCard = this.closest('.js-card')
  parentCard.classList.remove('content-is-expanded')
}

function readMore() {
  const parentCard = this.closest('.js-card')
  parentCard.classList.add('content-is-expanded')
}

const card = {
  init() {
    const readLessBtns = Array.from(document.querySelectorAll('.js-read-less-card'))
    const readMoreBtns = Array.from(document.querySelectorAll('.js-read-more-card'))

    readLessBtns.forEach((btn) => {
      btn.addEventListener('click', readLess);
    })

    readMoreBtns.forEach((btn) => {
      btn.addEventListener('click', readMore);
    })
  }
}

export default card
