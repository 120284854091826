'use strict';

import header from './header'

const showModalBtns = Array.from(document.querySelectorAll('.js-show-modal'))
const modals = Array.from(document.querySelectorAll('.js-modal'))
const closeButtons = Array.from(document.querySelectorAll('.js-close-modal'))

function openModal(clicked) {
  const i = clicked.dataset.target
  const matchModal = document.querySelector(`[data-modal="${i}"]`)

  if (matchModal) {
    matchModal.classList.add('is-active')
    document.body.classList.add('modal-is-active')
  }
}

function closeModal() {
  const modal = document.querySelector('.js-modal.is-active')
  modal.classList.remove('is-active')
  document.body.classList.remove('modal-is-active')
}

const manufacturers = {
  init() {
    // scroll
    let lastScrollPos = 0;
    let ticking = false;
    window.addEventListener('scroll', function(e) {
      lastScrollPos = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(function() {
          header.checkHeader()
          ticking = false
        })
        ticking = true
      }
    })

    if (showModalBtns.length) {
      showModalBtns.forEach((item) => {
        item.addEventListener('click', function() {
          openModal(this)
        });
      })

      closeButtons.forEach((item) => {
        item.addEventListener('click', function() {
          closeModal()
        });
      })
    }
  }
}

export default manufacturers
