import header from './header'
import orderBy from 'lodash/orderBy'

const btns = Array.from(document.querySelectorAll('.js-sort'))
const readMoreBtns = Array.from(document.querySelectorAll('.js-read-more'))
const readLessBtns = Array.from(document.querySelectorAll('.js-read-less'))
const moreDetailBtns = Array.from(document.querySelectorAll('.js-more-detail'))
const lessDetailBtns = Array.from(document.querySelectorAll('.js-less-detail'))
const toggleTable = document.querySelector('#toggle-table')
const tableEl = document.querySelector('#table')
const rows = document.querySelectorAll('.js-row')
const rowsContainer = document.querySelector('.js-rows')
const resetBtn = document.querySelector('#reset-table')
let currentBtn

function resetRows() {
  rowsContainer.innerHTML = ''
  rows.forEach(row => {
    rowsContainer.appendChild(row)
  })
}

function sortRows(btn) {
  const sortCriterion = btn.dataset.sort
  let sorted

  if (currentBtn !== btn) {
    btns.forEach(el => {
      el.classList.remove('is-active-asc')
      el.classList.remove('is-active-desc')
    })
  }

  currentBtn = btn

  if (btn.classList.contains('is-active-asc')) {
    btn.classList.remove('is-active-asc')
    btn.classList.add('is-active-desc')

    sorted = orderBy(rows, function(row) {
      const matchingRowCell = row.querySelector(`[data-target=${sortCriterion}]`)
      return matchingRowCell.textContent.toLowerCase()
    }, 'desc')
  } else {
    btn.classList.remove('is-active-desc')
    btn.classList.add('is-active-asc')

    sorted = orderBy(rows, function(row) {
      const matchingRowCell = row.querySelector(`[data-target=${sortCriterion}]`)
      return matchingRowCell.textContent.toLowerCase()
    }, 'asc')
  }

  rowsContainer.innerHTML = ''
  sorted.forEach(row => {
    rowsContainer.appendChild(row)
  })
}

function readMore(btn) {
  const parentCell = btn.closest('.js-cell')
  parentCell.classList.add('content-is-expanded')
}

function readLess(btn) {
  const parentCell = btn.closest('.js-cell')
  parentCell.classList.remove('content-is-expanded')
}

function moreDetail(btn) {
  const parentCell = btn.closest('.js-cell')
  const targetString = btn.dataset.target
  const matchingCells = Array.from(document.querySelectorAll(`[data-detail=${targetString}]`))
  matchingCells.forEach(cell => cell.classList.add('detail-is-expanded'))
  parentCell.classList.add('detail-is-expanded')
}

function lessDetail(btn) {
  const parentCell = btn.closest('.js-cell')
  const targetString = btn.dataset.target
  const matchingCells = Array.from(document.querySelectorAll(`[data-detail=${targetString}]`))
  matchingCells.forEach(cell => cell.classList.remove('detail-is-expanded'))
  parentCell.classList.remove('detail-is-expanded')
}

function toggleTableExpand() {
  if (tableEl.classList.contains('table-is-expanded')) {
    tableEl.classList.remove('table-is-expanded')
  } else {
    tableEl.classList.add('table-is-expanded')
  }
}

const table = {
  init() {
    if (toggleTable) {
      toggleTable.addEventListener('change', function() {
        toggleTableExpand()
      })
    }

    if (btns) {
      btns.forEach(btn => {
        btn.addEventListener('click', function() {
          sortRows(this)
        })
      })

      readMoreBtns.forEach(btn => {
        btn.addEventListener('click', function() {
          readMore(this)
        })
      })

      readLessBtns.forEach(btn => {
        btn.addEventListener('click', function() {
          readLess(this)
        })
      })

      moreDetailBtns.forEach(btn => {
        btn.addEventListener('click', function() {
          moreDetail(this)
        })
      })

      lessDetailBtns.forEach(btn => {
        btn.addEventListener('click', function() {
          lessDetail(this)
        })
      })

      resetBtn.addEventListener('click', function() {
        resetRows()
      });

      // scroll
      let lastScrollPos = 0;
      let ticking = false;
      window.addEventListener('scroll', function(e) {
        lastScrollPos = window.scrollY;
        if (!ticking) {
          window.requestAnimationFrame(function() {
            header.checkHeader()
            ticking = false
          })
          ticking = true
        }
      })
    }
  }
}

export default table
