'use strict';

import TweenMax from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import DrawSVGPlugin from './drawSVG'
import header from './header'
import 'array-flat-polyfill'

function hotspotAnims() {
  const targets = Array.from(document.querySelectorAll('.js-fade-in'))

  targets.forEach((target, index) => {
    const targetTop = target.getBoundingClientRect().top

    if (targetTop < window.innerHeight/2) {
      target.classList.add('is-visible')
    } else {
      target.classList.remove('is-visible')
    }
  })
}

function draw() {
  const windowBottom = window.scrollY + window.innerHeight
  const roadmapSvgGroup = document.querySelector('#roadmap-svg-group')
  const childNodes = Array.from(roadmapSvgGroup.childNodes).filter(item => item.nodeName !== '#text')

  const paths = childNodes.map(node => {
    const nodeChildren = Array.from(node.childNodes).filter(item => item.nodeName !== '#text')

    if (nodeChildren.length) {
      return nodeChildren
    } else {
      return node
    }
  }).flat().reverse()

  const roadmapSvgGroupMobile = document.querySelector('#roadmap-svg-group-mobile')
  const childNodesMobile = Array.from(roadmapSvgGroupMobile.childNodes).filter(item => item.nodeName !== '#text')

  const mobilePaths = childNodesMobile.map(node => {
    const nodeChildren = Array.from(node.childNodes).filter(item => item.nodeName !== '#text')

    if (nodeChildren.length) {
      return nodeChildren
    } else {
      return node
    }
  }).flat().reverse()

  const allPaths = [paths, mobilePaths].flat()

  allPaths.forEach(path => {
    const pathAttrs = Object.keys(path.attributes).map(key => path.attributes[key].nodeName)
    const pathTop = path.getBoundingClientRect().top + window.scrollY
    const percent = (windowBottom - pathTop - window.innerHeight/3) / path.getBoundingClientRect().height * 100

    TweenLite.set(path, {drawSVG:`${percent}%`})
  })
}

const roadmap = {
  init() {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
      // scroll
      let lastScrollPos = 0;
      let ticking = false;
      window.addEventListener('scroll', function(e) {
        lastScrollPos = window.scrollY;
        if (!ticking) {
          window.requestAnimationFrame(function() {
            hotspotAnims()
            header.checkHeader()
            draw()
            ticking = false
          })
          ticking = true
        }
      })

      // Force one-off draw on page load to set draw progress to zero
      draw()
    } else {
      // Firefox has a bug which breaks gsaps DrawSVGPlugin
      // So we don't draw the svg in firefox for now
      let lastScrollPos = 0;
      let ticking = false;
      window.addEventListener('scroll', function(e) {
        lastScrollPos = window.scrollY;
        if (!ticking) {
          window.requestAnimationFrame(function() {
            hotspotAnims()
            header.checkHeader()
            ticking = false
          })
          ticking = true
        }
      })
    }
  }
}

export default roadmap
