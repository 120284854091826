'use strict';

import utils from './utils';
import { TweenLite, TimelineLite } from 'gsap';

const navBtn = document.querySelector('#nav-btn');
const body = document.body;

function toggleNav() {
  utils.toggleClass(body, 'nav-is-active');
}

const nav = {
  hideNav() {
    body.classList.remove('nav-is-active');
  },

  init() {
    navBtn.addEventListener('click', function(e) {
      toggleNav();
    });
  }
}

export default nav
