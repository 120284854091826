'use strict';

import inView from 'in-view'

const header = {
  checkHeader() {
    const pageTitle = document.querySelector('.js-page-title')
    const pageTitleTop = pageTitle.getBoundingClientRect().top
    const offset = document.querySelector('#header').offsetHeight
    if (pageTitleTop - offset < 0) {
      document.body.classList.add('body-is-scrolled')
    } else {
      document.body.classList.remove('body-is-scrolled')
    }
  },

  init() {
    inView('#title')
    .on('enter', el => {
      document.body.classList.remove('body-is-scrolled')
    })
    .on('exit', el => {
      document.body.classList.add('body-is-scrolled')
    });
  }
}

export default header
