'use strict';

import nav from './modules/nav';
import header from './modules/header';
import modal from './modules/modal';
import table from './modules/table';
import journey from './modules/journey';
import roadmap from './modules/roadmap';
import resources from './modules/resources';
import manufacturers from './modules/manufacturers';
import card from './modules/card';

nav.init();
header.init();
modal.init();

window.onload = function() {
  if (window.scrollY > 0) {
    document.body.classList.add('body-is-scrolled')
  }

  document.body.classList.remove('is-loading')

  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual'
  }

  // journey
  const journeySvgContainer = document.querySelector('#journey-svg-container');
  if (journeySvgContainer) {
    window.scrollTo(0, 0)
    journey.init()
  }

  // roadmap
  const roadmapSection = document.querySelector('section.roadmap');
  if (roadmapSection) {
    window.scrollTo(0, 0)
    roadmap.init()
  }

  // rankings
  const tableEl = document.querySelector('#table')
  if (tableEl) {
    table.init()
  }

  const resourcesEl = document.querySelector('#resources')
  if (resourcesEl) {
    resources.init()
    card.init()
  }

  const manufacturersEl = document.querySelector('#manufacturers')
  if (manufacturersEl) {
    manufacturers.init()
  }
}
