const hotspots = Array.from(document.querySelectorAll('.js-hotspot'))
const modals = Array.from(document.querySelectorAll('.js-modal'))
const closeButtons = Array.from(document.querySelectorAll('.js-close-modal'))

function openModal(clicked) {
  const i = clicked.dataset.target
  const matchModal = document.querySelector(`[data-modal="${i}"]`)

  if (matchModal) {
    matchModal.classList.add('is-active')
    document.body.classList.add('modal-is-active')
  }
}

function closeModal() {
  const modal = document.querySelector('.js-modal.is-active')
  modal.classList.remove('is-active')
  document.body.classList.remove('modal-is-active')
}

const modal = {
  init() {
    if (hotspots.length) {
      hotspots.forEach((item) => {
        item.addEventListener('click', function() {
          openModal(this)
        });
      })

      closeButtons.forEach((item) => {
        item.addEventListener('click', function() {
          closeModal()
        });
      })
    }
  }
}

export default modal
