'use strict';

import header from './header'

const resources = {
  init() {
    // scroll
    let lastScrollPos = 0;
    let ticking = false;
    window.addEventListener('scroll', function(e) {
      lastScrollPos = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(function() {
          header.checkHeader()
          ticking = false
        })
        ticking = true
      }
    })
  }
}

export default resources
