'use strict';

import TweenMax from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import DrawSVGPlugin from './drawSVG'
import 'array-flat-polyfill'

const introTl = new TimelineLite()

function checkJourney() {
  const scrollBtn = document.querySelector('#scroll-cta')
  const journeyTop = document.querySelector('#journey').getBoundingClientRect().top

  if (introTl.isActive()) {
    TweenMax.killAll(true)
  }

  if (journeyTop < window.innerHeight/2) {
    scrollBtn.classList.add('is-hidden')
  } else {
    scrollBtn.classList.remove('is-hidden')
  }
}

function scrollDown() {
  const journey = document.querySelector('#journey')
  TweenLite.to(window, 1, {scrollTo: journey, autoKill:false})
}

function hotspotAnims() {
  const targets = Array.from(document.querySelectorAll('.js-fade-in'))

  targets.forEach((target, index) => {
    const targetTop = target.getBoundingClientRect().top

    if (targetTop < window.innerHeight/2) {
      target.classList.add('is-visible')
    } else {
      target.classList.remove('is-visible')
    }
  })
}

function draw() {
  const windowBottom = window.scrollY + window.innerHeight

  const paths = Array.from(document.querySelectorAll('.svg-group')).map(group => {
    return Array.from(group.childNodes).filter(item => item.nodeName !== '#text')
  }).flat().reverse()

  const mobilePaths = Array.from(document.querySelectorAll('.js-svg-mobile')).map(item => {
    const svg = item.querySelector('svg')
    const svgGroup = Array.from(svg.childNodes).filter(node => node.nodeName !== 'defs')
    const svgChildren = svgGroup.map(group => Array.from(group.childNodes)).flat()
    return svgChildren
  }).flat()

  const allPaths = [paths, mobilePaths].flat()

  allPaths.forEach(path => {
    const pathAttrs = Object.keys(path.attributes).map(key => path.attributes[key].nodeName)
    const pathTop = path.getBoundingClientRect().top + window.scrollY
    const percent = (windowBottom - pathTop - window.innerHeight/3) / path.getBoundingClientRect().height * 100

    if (pathAttrs.includes('fill') || window.getComputedStyle(path).fill !== 'none') {
      TweenLite.set(path, {opacity:`${percent/100}`})
    } else {
      TweenLite.set(path, {drawSVG:`${percent}%`})
    }
  })
}

function dirtAnim() {
  const dirtLayers = Array.from(document.querySelectorAll('.js-dirt-layer'))

  dirtLayers.forEach((layer, index) => {
    const layerHeight = layer.offsetHeight / 5 / 2
    const layerBottom = (layer.getBoundingClientRect().bottom / 5 / 2) * (index + 1) - window.scrollY
    const coefficient = layerBottom / layerHeight

    TweenMax.set(layer, {opacity: coefficient})
  })
}

const journey = {
  init() {
    const introFadeEls = Array.from(document.querySelectorAll('.js-intro-fade-in'))
    introTl.staggerFromTo(introFadeEls, 0.5, {opacity: 0, y: 20}, {opacity: 1, y: 0}, 0.25, null, function() {
      const scrollBtn = document.querySelector('#scroll-cta')
      TweenLite.set(scrollBtn, {clearProps: 'all'})
      scrollBtn.classList.remove('is-hidden')
    })

    if (navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
      // scroll
      let lastScrollPos = 0;
      let ticking = false;
      window.addEventListener('scroll', function(e) {
        lastScrollPos = window.scrollY;
        if (!ticking) {
          window.requestAnimationFrame(function() {
            dirtAnim()
            draw()
            hotspotAnims()
            checkJourney()
            ticking = false
          })
          ticking = true
        }
      })

      // Force one-off draw on page load to set draw progress to zero
      draw()
    } else {
      // Firefox has a bug which breaks gsaps DrawSVGPlugin
      // So we don't draw the svg in firefox for now
      let lastScrollPos = 0;
      let ticking = false;
      window.addEventListener('scroll', function(e) {
        lastScrollPos = window.scrollY;
        if (!ticking) {
          window.requestAnimationFrame(function() {
            dirtAnim()
            hotspotAnims()
            checkJourney()
            ticking = false
          })
          ticking = true
        }
      })
    }

    const scrollBtn = document.querySelector('#scroll-cta')
    scrollBtn.addEventListener('click', function(e) {
      scrollDown()
    })
  }
}

export default journey
